<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>
    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>

      <div class="global-lists-filters-container">
        <b-field>
          <b-field label="Almacenes" expanded label-position="inside">
            <b-taginput
              :data="warehouses"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              field="NOMBRE_ALM"
              icon="warehouse"
              v-model="filterWarehouses"
              @add="(value) => changeFilterValue()"
              @remove="(value) => changeFilterValue()"
              ellipsis
              placeholder="Nombre de almacen"
              aria-close-label="Borrar almacen seleccionado"
              check-infinite-scroll
            >
            </b-taginput>
          </b-field>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field label="Filtrar por proveedor" label-position="inside">
          <b-select
            placeholder="Filtrar por proveedor"
            icon="account-group-outline"
            v-model="filterProvider"
            class="global-lists-filters-control"
          >
            <option value="">Todos los provedores</option>
            <option
              v-for="provider in providers"
              :value="provider"
              :key="provider.NOMBRE_PRV"
            >
              {{ provider.NOMBRE_PRV }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de Recepciones de material:
      <b>{{ filteredMaterialReceptions.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="filteredMaterialReceptions"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :checked-rows.sync="checkedOrders"
      :current-page="currentPage"
      :selected.sync="selected"
      ref="ProductsTable"
      paginated
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.CLAVE_PED}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="desc"
      checkbox-position="left"
      default-sort="CLAVE_PED"
      scrollable
    >
      <template #empty>
        <div class="has-text-centered">No hay recepciones de material</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          v-bind="column"
          :key="column.field"
          :visible="column.display"
          :label="column.label"
          :custom-sort="column.customSort"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>
          <span v-if="column.money">{{
            props.row[column.field] | money("MXN", 2)
          }}</span>
          <span v-if="!column.money">{{ props.row[column.field] }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="media-content">
            <div class="content">
              <div class="controls">
                <b-button
                  type="is-primary"
                  @click="showMaterialReception(props.row)"
                  >Descargar recepción de material</b-button
                >
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import PrintPurchaseOrder from "../../Global/components/print-formats/PrintPurchaseOrder";

import {
  openConfirmationModalSucces,
  openConfirmationModalDanger,
} from "@/utils/component-fns";
import PrintMaterialReception from "../../Global/components/print-formats/PrintMaterialReception.vue";

export default {
  name: "MaterialReceptionsList",
  props: [],
  components: {},
  data() {
    return {
      perPage: 50,
      checkedOrders: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "CLAVE_PED",
            label: "Folio O.C.",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_ALM",
            label: "Almacén",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_PRV",
            label: "Proveedor",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "formatedDate",
            label: "Fecha",
            sortable: true,
            searchable: true,
            display: true,
            customSort: this.sortByDate,
          },
          {
            field: "REALIZO_MOV",
            label: "Creador de la recepción",
            sortable: true,
            searchable: true,
            display: true,
          },
        ],
        "material-receptions-list-columns"
      ),
      filterWarehouses: [],
      filterProvider: "",
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  async mounted() {
    this.filterWarehouses = [];
    if (this.userInformation.ALMACENES.length > 0) {
      for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
        for (const singleWarehouse of this.warehouses) {
          if (singleSelectedWarehouse === singleWarehouse._id) {
            this.filterWarehouses.push(singleWarehouse);
          }
        }
      }
    }
  },
  async created() {
    moment.locale("es");

    let allPromises = [];

    allPromises.push(
      this.$store.dispatch("GETMATERIALRECEPTIONS", {
        startDate: this.startDate,
        endDate: new Date(
          new Date(this.endDate).getFullYear(),
          new Date(this.endDate).getMonth(),
          new Date(this.endDate).getDate() + 1
        ),
        warehouses: this.filterWarehouses.map((singleWarehouse) => {
          return singleWarehouse._id;
        }),
      })
    );

    await Promise.all(allPromises);
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(
        this.$store.dispatch("GETMATERIALRECEPTIONS", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouses: this.filterWarehouses.map((singleWarehouse) => {
            return singleWarehouse._id;
          }),
        })
      );

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_PED).getTime() || -Infinity) -
          (new Date(a.FECHA_PED).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_PED).getTime() || -Infinity) -
          (new Date(b.FECHA_PED).getTime() || -Infinity)
        );
      }
    },
    async changeFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETMATERIALRECEPTIONS", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouses: this.filterWarehouses.map((singleWarehouse) => {
              return singleWarehouse._id;
            }),
          })
        );

        await Promise.all(allPromises);
      }
    },
    showMaterialReception(materialReceptionInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PrintMaterialReception,
        props: {
          materialReceptionInformation: materialReceptionInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    warehouses() {
      this.filterWarehouses = [];

      if (this.userInformation.ALMACENES.length > 0) {
        for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
          for (const singleWarehouse of this.warehouses) {
            if (singleSelectedWarehouse === singleWarehouse._id) {
              this.filterWarehouses.push(singleWarehouse);
            }
          }
        }
      }

      this.reloadInformation();
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "purchase-oders-list-columns"
        );
      },
      deep: true,
    },
  },
  computed: {
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        let providerWithProps = { ...singleProvider };
        return providerWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
      });
    },
    materialReceptions() {
      return this.$store.getters.MATERIALRECEPTIONS.map(
        (singleMaterialReception) => {
          let singleMaterialReceptionWithProps = { ...singleMaterialReception };

          singleMaterialReceptionWithProps.NOMBRE_ALM =
            singleMaterialReception.CLAVE_ALMACEN.NOMBRE_ALM;
          singleMaterialReceptionWithProps.NOMBRE_PRV =
            singleMaterialReception.CLAVE_PRV.NOMBRE_PRV;
          singleMaterialReceptionWithProps.formatedDate = moment(
            singleMaterialReception.FECHA_PED
          ).format("DD-MM-YYYY HH:mm");

          return singleMaterialReceptionWithProps;
        }
      );
    },
    filteredMaterialReceptions() {
      if (this.filterProvider === "") {
        return this.materialReceptions.map((singleMaterialReception) => {
          let singleMaterialReceptionWithProps = { ...singleMaterialReception };
          return singleMaterialReceptionWithProps;
        });
      }
      return this.materialReceptions.filter((singleMaterialReception) => {
        if (singleMaterialReception.CLAVE_PRV === this.filterProvider._id) {
          return true;
        }
        return false;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    userInformation() {
      return this.$store.getters.USER;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
