<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title print-hide">
      Imprimir recepción de material
    </h1>

    <div class="container-print" ref="requestOrder">
      <img
        v-if="companyInformation.LOGO_URL"
        :src="companyInformation.LOGO_URL"
        alt="Company logo"
        style="margin-top: 1rem; width: 20%; margin-bottom: 1rem"
      />
      <p class="special-margin">
        <strong>
          {{
            companyInformation.LEYENDA_SUPERIOR
              ? companyInformation.LEYENDA_SUPERIOR
              : ""
          }}</strong
        >
      </p>
      <table width="100%">
        <tr class="logotype-container">
          <td>
            <div class="logotype-type" :style="[backgroundStyle, textStyle]">
              Recepción de material {{ " " }} #{{
                this.materialReceptionInformation.CLAVE_PED
              }}
            </div>
          </td>
        </tr>
      </table>
      <br />
      <div style="display: flex">
        <div style="margin-bottom: 20px; width: 50%">
          <strong>Sucursal de Envío</strong><br />
        </div>

        <div style="margin-bottom: 20px; width: 50%; text-align: end">
          <strong>{{
            formatCreationDate(materialReceptionInformation.FECHA)
          }}</strong
          ><br />
        </div>
      </div>
      <table width="100%">
        <tr>
          <td>
            <table width="100%">
              <tr>
                <td>
                  <strong>Nombre:</strong>
                  {{ companyName }} <br />
                  <strong>Unidad:</strong>
                  {{ materialReceptionInformation.CLAVE_ALMACEN.NOMBRE_ALM
                  }}<br />
                  <strong>Dirección:</strong>
                  {{ materialReceptionInformation.CLAVE_ALMACEN.DOMICIL_ALM }}
                  {{ materialReceptionInformation.CLAVE_ALMACEN.NUM_EXTER }}
                  {{ materialReceptionInformation.CLAVE_ALMACEN.NUM_INTER }}
                  {{
                    materialReceptionInformation.CLAVE_ALMACEN.NOMBRE_MUNICIPIO
                  }},
                  {{ materialReceptionInformation.CLAVE_ALMACEN.ESTADO_ALM }},
                  {{ materialReceptionInformation.CLAVE_ALMACEN.CP_ALM }}<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <br />
      <div
        style="padding-left: 20px; padding-top: 10px; padding-bottom: 10px"
        :style="backgroundStyle"
      >
        <strong :style="textStyle">Información del proveedor</strong><br />
      </div>
      <div>
        <table width="100%">
          <tr>
            <td style="width: 50%; padding-bottom: 20px; padding-top: 20px">
              <strong>Nombre:</strong>
              {{ materialReceptionInformation.CLAVE_PRV.NOMBRE_PRV }}<br />
              <strong>Dirección:</strong>
              {{ materialReceptionInformation.CLAVE_PRV.DOMICIL_PRV }}
              {{ materialReceptionInformation.CLAVE_PRV.ESTADO_PRV }}
              {{ materialReceptionInformation.CLAVE_PRV.NOMBRE_MUNICIPIO_PRV }}
              {{ materialReceptionInformation.CLAVE_PRV.CP_PRV }}<br />
            </td>
            <td style="width: 50%; padding-bottom: 20px; padding-top: 20px">
              <strong>Télefono:</strong>
              {{
                this.materialReceptionInformation.CLAVE_PRV.CEL_PRV
                  ? this.materialReceptionInformation.CLAVE_PRV.CEL_PRV
                  : "NA"
              }}<br />
            </td>
          </tr>
        </table>
      </div>

      <br />
      <div class="table-overflow">
        <table
          width="100%"
          style="border-collapse: collapse; border-bottom: 1px solid #eee"
        >
          <tr>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              #
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Clave
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Artículo
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Ubicación
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Cantidad
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Unidad
            </td>
          </tr>

          <div
            class="display-content"
            v-for="(
              singleItem, index
            ) of materialReceptionInformation.RECEPCION_DET"
            :key="singleItem.RENGLON"
          >
            <tr class="article-element">
              <td class="row" style="text-align: center">{{ index + 1 }}</td>
              <td class="row" style="text-align: center">
                {{ singleItem.CLAVE_ART.CLAVE_ART }}
              </td>
              <td class="row" style="text-align: center">
                {{ singleItem.CLAVE_ART.DESCRIPCION }}
              </td>
              <td class="row" style="text-align: center">
                {{ singleItem.CLAVE_ART.UBICACION }}
              </td>
              <td class="row" style="text-align: center">
                {{ singleItem.CANTIDAD_RE }}
              </td>
              <td class="row" style="text-align: center">
                Unidad de medida base
              </td>
            </tr>
          </div>
        </table>
        <br />
      </div>

      <div style="margin-bottom: 20px; font-size: 1rem">
        <strong>Peso:</strong> {{ getTotalWeight() }} <strong>Kg</strong>
      </div>
      <div style="margin-bottom: 20px; font-size: 1rem">
        <strong>Volumen:</strong> {{ getTotalVolume() }} <strong>M³</strong>
      </div>

      <p class="special-margin-top not-cut-printing">
        <strong>
          {{
            companyInformation.LEYENDA_INFERIOR
              ? companyInformation.LEYENDA_INFERIOR
              : ""
          }}</strong
        >
      </p>
      <div v-if="printing" style="margin-top: 1.5rem" class="not-cut-printing">
        Este pdf fue impreso por coconutcontrol.com
      </div>
    </div>

    <div class="container-print">
      <div class="add-global-controls">
        <b-button type="is-success" @click="print(0)">Descargar</b-button>

        <b-button type="is-danger" @click="closeModal()" class="cancel-button"
          >Regresar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import { EventBus } from "../../../../event-bus";
import { openConfirmationModalSucces } from "../../../../utils/component-fns";
import { hexToRGB } from "../../../../utils/fns";
// @ is an alias to /src
export default {
  name: "PrintMaterialReception",
  props: ["materialReceptionInformation"],
  components: {},
  data() {
    return {
      printing: false,
      printWithoutPrices: false,
    };
  },
  mounted() {
    moment.locale("es");

    console.log(this.materialReceptionInformation);
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc print the purchase order with the desire format
     */
    async print(type) {
      this.createPdfHtml(type, "print");
      setTimeout(() => {
        this.printWithoutPrices = false;
      }, 500);
    },
    /**
     *
     * @desc cretes or generates a pdf file
     */
    async createPdfHtml(type, confirmPurchaseOrder = false) {
      if (type === 0) {
        this.printWithoutPrices = true;
      } else {
        this.printWithoutPrices = false;
      }
      this.printing = true;
      let pdfOptions = {
        filename: `recepción de material ${this.materialReceptionInformation.CLAVE_PED} ${this.materialReceptionInformation.CLAVE_PRV.NOMBRE_PRV} ${this.companyName}.pdf`,
        pagebreak: {
          avoid: [
            ".article-element",
            ".observations-element",
            ".not-cut-printing",
          ],
        },
        scale: 1,
        image: { type: "jpeg", quality: 0.98 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        margin: [0, 0.2, 0, 0.2],
      };
      let result;
      if (confirmPurchaseOrder && this.isMobileNavigator()) {
        result = await html2pdf()
          .set(pdfOptions)
          .from(this.$refs.requestOrder)
          .output("datauristring");
      } else {
        result = await html2pdf()
          .set(pdfOptions)
          .from(this.$refs.requestOrder)
          .save()
          .output("datauristring");
      }
      this.printing = false;
      return result;
    },
    formatCreationDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     */
    startConfirmEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    getTotalWeight() {
      let totalWeight = 0;
      for (const singleArticle of this.materialReceptionInformation
        .RECEPCION_DET) {
        if (singleArticle.CLAVE_ART && singleArticle.CLAVE_ART.CANT_PESO) {
          totalWeight +=
            singleArticle.quantity * singleArticle.CLAVE_ART.CANT_PESO;
        }
      }
      return totalWeight;
    },
    getTotalVolume() {
      let totalWeight = 0;
      for (const singleArticle of this.materialReceptionInformation
        .RECEPCION_DET) {
        if (singleArticle.CLAVE_ART && singleArticle.CLAVE_ART.CANT_VOL) {
          totalWeight +=
            singleArticle.quantity * singleArticle.CLAVE_ART.CANT_VOL;
        }
      }
      return totalWeight;
    },
  },
  computed: {
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    companyName() {
      return this.$store.getters.COMPANY_INFORMATION.name;
    },
    companyEmails() {
      return this.$store.getters.COMPANIES_EMAILS;
    },
    permissions() {
      return this.$store.getters.USER.permissions.permissions;
    },
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "CO" || singleEmployee.TIPO === "VC";
      });
    },
    backgroundStyle() {
      return {
        "background-color": this.companyInformation.FONDO
          ? hexToRGB(this.companyInformation.FONDO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    backgroundSecondaryStyle() {
      return {
        "background-color": this.companyInformation.FONDO_SECUNDARIO
          ? hexToRGB(this.companyInformation.FONDO_SECUNDARIO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    textStyle() {
      return {
        color: this.companyInformation.TEXTO
          ? hexToRGB(this.companyInformation.TEXTO, 1)
          : "#363636",
      };
    },
    textSecondaryStyle() {
      return {
        color: this.companyInformation.TEXTO_SECUNDARIO
          ? hexToRGB(this.companyInformation.TEXTO_SECUNDARIO, 1)
          : "#363636",
      };
    },
  },
  watch: {
    companyEmails() {
      this.selectedEmail = this.companyEmails[0]._id;
    },
  },
};
</script>

<style scoped>
@import "./style/PrintPurchaseOrder.css";
@import "../../style/Global.css";
</style>
